import React, { useContext } from "react";
import { ThemeProvider } from "styled-components";
import { generatePreviousRound } from "bracket-system/core/match-functions";
import { calculateSVGDimensions } from "bracket-system/core/calculate-svg-dimensions";
import {
	matchContext,
	MatchContextProvider,
} from "bracket-system/core/match-context";

import { DoubleElimLeaderboardProps } from "../types";
import { defaultStyle, getCalculatedStyles } from "../settings";

import defaultTheme from "../themes/themes";

import UpperBracket from "./upper-bracket";
import LowerBracket from "./lower-bracket";
import FinalGame from "./final-game";
import ExtraFinal from "./extra-final";
import { useEffect } from "react";

function findTheFinals(matches) {
	const finalsCountInUpper =
		matches.upper?.filter((match) => !match.nextMatchId)?.length ?? 0;
	const finalsCountInLower =
		matches.lower?.filter((match) => !match.nextMatchId)?.length ?? 0;

	const isQualifierRound = finalsCountInUpper > 0 && finalsCountInLower > 0;
	const isFinalInUpper = finalsCountInUpper === 1;
	const isFinalInLower = finalsCountInLower === 1;

	let finalMatch = null;
	let finalsArray = [];
	let isConverging = false;

	if (isQualifierRound) {
		finalsArray = matches.upper
			?.filter((match) => !match.nextMatchId)
			.concat(matches.lower?.filter((match) => !match.nextMatchId) ?? []);
	}

	// No GF scenario
	if (isFinalInLower && isFinalInUpper) {
		isConverging = false;
	} else if (isFinalInLower) {
		const lastUpper = matches.upper.find((match) => {
			const hasNextMatchInUpper = matches.upper.some(
				(m) => m.id === match.nextMatchId
			);
			return !hasNextMatchInUpper;
		});
		finalMatch = matches.lower.find(
			(match) => match.id === lastUpper.nextMatchId
		);
		finalsArray = [
			finalMatch,
			matches.lower.find((m) => m.id === finalMatch?.nextMatchId),
		].filter((m) => m?.id);

		isConverging = !!finalMatch;
	} else if (isFinalInUpper) {
		if (matches.lower?.length > 0) {
			const lastLower = matches.lower.find((match) => {
				const hasNextMatchInLower = matches.lower.some(
					(m) => m.id === match.nextMatchId
				);
				return !hasNextMatchInLower;
			});
			finalMatch = matches.upper.find(
				(match) => match.id === lastLower.nextMatchId
			);

			isConverging = !!finalMatch;
		} else {
			finalMatch = matches.upper.find((m) => !m.nextMatchId);
		}

		finalsArray = [
			finalMatch,
			matches.upper.filter((m) => !m.nextMatchId),
		].filter((m) => m?.id);
	}

	return { finalMatch, finalsArray, isConverging, isQualifierRound };
}
const DoubleEliminationBracket = ({
	matches,
	officialMatches,
	highlightMatchIds,
	matchComponent,
	currentRound,
	onMatchClick,
	onPartyClick,
	svgWrapper: SvgWrapper = ({ children }) => <>{children}</>,
	theme = defaultTheme,
	options: { style: inputStyle } = {
		style: defaultStyle,
	},
	onUpperClick,
	onLowerClick,
	onFinalsClick,
	onColumnClick,
	customHeaderNames,
	lockedColumns,
	hideSeriesScore,
	isPartialBracket,
}: DoubleElimLeaderboardProps) => {
	const style = {
		...defaultStyle,
		...inputStyle,
		roundHeader: {
			...defaultStyle.roundHeader,
			...inputStyle.roundHeader,
		},
		lineInfo: {
			...defaultStyle.lineInfo,
			...inputStyle.lineInfo,
		},
	};

	if (!matches) {
		return <></>;
	}

	const calculatedStyles = getCalculatedStyles(style, hideSeriesScore);
	const { roundHeader, columnWidth, canvasPadding, rowHeight } =
		calculatedStyles;
	const { finalMatch, finalsArray, isConverging, isQualifierRound } =
		findTheFinals(matches);

	const hasMultipleFinals = finalsArray?.length > 1;

	const generateColumn = (matchesColumn, listOfMatches) => {
		const previousMatchesColumn = generatePreviousRound(
			matchesColumn,
			listOfMatches
		);

		if (previousMatchesColumn.length > 0) {
			return [
				...generateColumn(previousMatchesColumn, listOfMatches),
				previousMatchesColumn,
			];
		}
		return [previousMatchesColumn];
	};
	const generate2DBracketArray = (
		finalMatches,
		listOfMatches,
		includeFinal
	) => {
		let result = finalMatches
			? [...generateColumn(finalMatches, listOfMatches), []].filter(
					(arr) => arr.length > 0
			  )
			: [];

		if (includeFinal) {
			result.push(
				finalMatches.filter((fm) => listOfMatches.some((m) => m.id === fm.id))
			);
		}

		return result;
	};

	const hasLower = matches.lower?.length > 0;
	const upperColumns = generate2DBracketArray(
		finalsArray,
		matches.upper,
		!hasLower || isQualifierRound || isPartialBracket
	);
	const lowerColumns = generate2DBracketArray(
		finalsArray,
		matches.lower,
		isQualifierRound || isPartialBracket
	);

	// [
	//   [ First column ]
	//   [ 2nd column ]
	//   [ 3rd column ]
	//   [ lastGame ]
	// ]

	const totalNumOfRounds =
		lowerColumns && !isQualifierRound
			? lowerColumns.length + 1 + (hasMultipleFinals && finalsArray.length - 1)
			: upperColumns.length;
	const upperBracketDimensions = calculateSVGDimensions(
		upperColumns.length > 0 ? upperColumns[0].length : 0,
		upperColumns.length,
		rowHeight,
		columnWidth,
		canvasPadding,
		roundHeader,
		currentRound
	);

	let gameHeight;
	let lowerBracketDimensions;
	let fullBracketDimensions;

	if (matches.lower?.length > 0) {
		lowerBracketDimensions = calculateSVGDimensions(
			lowerColumns[0].length,
			lowerColumns.length,
			rowHeight,
			columnWidth,
			canvasPadding,
			roundHeader,
			currentRound
		);
		fullBracketDimensions = calculateSVGDimensions(
			lowerColumns[0].length,
			totalNumOfRounds,
			rowHeight,
			columnWidth,
			canvasPadding,
			roundHeader,
			currentRound
		);
		gameHeight =
			upperBracketDimensions.gameHeight + lowerBracketDimensions?.gameHeight;
	} else {
		fullBracketDimensions = upperBracketDimensions;
		gameHeight = upperBracketDimensions.gameHeight;
	}

	const { gameWidth } = fullBracketDimensions;
	const { startPosition } = upperBracketDimensions;

	return (
		<ThemeProvider theme={theme}>
			<SvgWrapper
				bracketWidth={gameWidth ?? 1}
				bracketHeight={gameHeight ?? 1}
				startAt={startPosition}
				background={theme.canvasBackground}
			>
				<svg
					height={gameHeight ?? 0}
					width={gameWidth ?? 0}
					viewBox={`0 0 ${gameWidth} ${gameHeight}`}
				>
					<MatchContextProvider>
						<MatchColoringProvider
							newHighlightedMatchIds={highlightMatchIds}
							newOfficialMatches={officialMatches}
						/>
						<UpperBracket
							{...{
								columns: upperColumns,
								calculatedStyles,
								gameHeight,
								gameWidth,
								onMatchClick,
								onPartyClick,
								matchComponent,
								lowerColumns,
								onUpperClick,
								onColumnClick,
								customHeaderNames: customHeaderNames?.upper ?? [],
								lockedColumns: lockedColumns?.upper ?? [],
								hideSeriesScore,
								isQualifierRound: isQualifierRound && !isConverging,
							}}
						/>
						{matches.lower?.length > 0 && (
							<LowerBracket
								{...{
									columns: lowerColumns,
									calculatedStyles,
									gameHeight,
									gameWidth,
									onMatchClick,
									onPartyClick,
									matchComponent,
									upperBracketHeight: upperBracketDimensions.gameHeight,
									onLowerClick,
									onColumnClick,
									customHeaderNames: customHeaderNames?.lower ?? [],
									lockedColumns: lockedColumns?.lower ?? [],
									hideSeriesScore,
									isQualifierRound: isQualifierRound && !isConverging,
								}}
							/>
						)}
						{isConverging && !isQualifierRound && (
							<FinalGame
								{...{
									upperColumns,
									lowerColumns,
									match: finalMatch,
									numOfUpperRounds: upperColumns.length,
									numOfLowerRounds: lowerColumns.length,
									bracketSnippet: {
										previousTopMatch: upperColumns[upperColumns.length - 1][0],
										previousBottomMatch:
											lowerColumns[lowerColumns.length - 1][0],
										currentMatch: finalMatch,
									},
									upperBracketHeight: upperBracketDimensions.gameHeight,
									lowerBracketHeight: lowerBracketDimensions?.gameHeight,
									calculatedStyles,
									columnIndex: lowerColumns.length,
									rowIndex: 0,
									gameHeight,
									gameWidth,
									matchComponent,
									onMatchClick,
									onPartyClick,
									onFinalsClick,
									onColumnClick,
									hideSeriesScore,
								}}
							/>
						)}
						{finalsArray?.length > 1 && !isQualifierRound && (
							<ExtraFinal
								{...{
									match: finalsArray[1],
									numOfUpperRounds: upperColumns.length,
									numOfLowerRounds: lowerColumns.length,
									bracketSnippet: {
										previousBottomMatch: finalsArray[0],
										currentMatch: finalsArray[1],
									},
									upperBracketHeight: upperBracketDimensions.gameHeight,
									lowerBracketHeight: lowerBracketDimensions?.gameHeight,
									calculatedStyles,
									columnIndex: lowerColumns.length + 1,
									rowIndex: 0,
									gameHeight,
									gameWidth,
									matchComponent,
									onMatchClick,
									onPartyClick,
									hideSeriesScore,
								}}
							/>
						)}
					</MatchContextProvider>
				</svg>
			</SvgWrapper>
		</ThemeProvider>
	);
};

function MatchColoringProvider({ newHighlightedMatchIds, newOfficialMatches }) {
	const {
		state: { highlightedMatchIds, officialMatches },
		dispatch,
	} = useContext(matchContext);

	useEffect(() => {
		if (highlightedMatchIds?.length !== newHighlightedMatchIds?.length) {
			dispatch({
				type: "SET_MATCH_HIGHLIGHTS",
				payload: {
					highlightedMatchIds: newHighlightedMatchIds,
				},
			});
		}

		if (officialMatches?.length !== newOfficialMatches?.length) {
			dispatch({
				type: "SET_OFFICIAL_PICKS",
				payload: {
					officialMatches: newOfficialMatches,
				},
			});
		}
	});

	return <></>;
}

export default DoubleEliminationBracket;
