import { Button, Container, makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { SetLoading } from "common/components/Loader/Loader";
import { connect } from "common/connect";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import * as React from "react";
import { useEffect } from "react";
import { Colors } from "styles/Colors";
import { Add, Edit, FileCopy } from "@material-ui/icons";
import { SurveyDto } from "common/types/Survey/SurveyDto";
import SurveyService from "services/SurveyService";

const propKeys = {
	allSurveys: StoreKeys.ADMIN.SURVEYS.ALL,
};

const classStyles = makeStyles(() => {
	return {
		dataGrid: {
			backgroundColor: Colors.panel,
			"& .MuiTablePagination-displayedRows": {
				color: Colors.primary,
			},
			"& .MuiTablePagination-actions button": {
				color: Colors.primary,
				"&:disabled": {
					color: Colors.panelLighterColor,
				},
			},
			"& .MuiDataGrid-columnHeaderTitle": {
				color: Colors.primary,
			},
		},
		dataGridRow: {
			cursor: "pointer",
			color: Colors.primary,
			"&:hover": {
				backgroundColor: `${Colors.panelLight}!important`,
			},
			"&:focus-within": {
				backgroundColor: `${Colors.panelLighterColor}!important`,
			},
		},
		button: {
			color: Colors.primary,
			backgroundColor: Colors.panelLight,
			margin: "0 10px",
			"&:disabled": {
				color: Colors.panelLighterColor,
				border: 0,
			},
		},
	};
});

const dateFormatter = (object) => object.value.split("T")[0];

const columns = [
	{ field: "surveyId", headerName: "Survey ID", hide: true },
	{ field: "title", headerName: "Title", flex: 1 },
	{ field: "description", headerName: "Description", flex: 1 },
	{
		field: "openTime",
		headerName: "Open Date",
		width: 150,
		valueFormatter: dateFormatter,
	},
	{
		field: "closeTime",
		headerName: "Close Date",
		width: 150,
		valueFormatter: dateFormatter,
	},
	{
		field: "created",
		headerName: "Date Created",
		width: 150,
		valueFormatter: dateFormatter,
	},
	{
		field: "modified",
		headerName: "Date Modified",
		width: 150,
		valueFormatter: dateFormatter,
	},
];

/**
 * @typedef ComponentProps
 * @type {object}
 * @property {SurveyDto} allSurveys
 */

/**
 * @param {ComponentProps} props
 */
export function SurveySelectorC(props) {
	const { allSurveys } = props;
	const [rowsSelected, setRowsSelected] = React.useState([]);
	const classNames = classStyles();

	useEffect(() => {
		if (!allSurveys) {
			SetLoading(true, "Getting Survey");
			SurveyService.getAllSurveys().then((allSurveys) => {
				allSurveys.forEach((s) => (s.id = s.surveyId));
				Store.set(StoreKeys.ADMIN.SURVEYS.ALL, allSurveys);
				SetLoading(false);
			});
		}
	});

	function addSurvey() {
		Store.set(StoreKeys.ADMIN.SURVEYS.CURRENT, new SurveyDto());
	}

	function editSurvey() {
		if (rowsSelected.length) {
			SetLoading(true, "Getting Survey");

			const surveyId = rowsSelected[0];
			SurveyService.getSpecificSurvey(surveyId).then((survey) => {
				Store.set(StoreKeys.ADMIN.SURVEYS.CURRENT, survey);
				SetLoading(false);
			});
		}
	}

	function cloneSurvey() {
		if (rowsSelected.length) {
			SetLoading(true, "Getting Survey");

			const surveyId = rowsSelected[0];
			SurveyService.getSpecificSurvey(surveyId).then((toClone) => {
				const survey = {
					...toClone,
				};

				survey.surveyId = 0;
				survey.openTime = null;
				survey.closeTime = null;

				// Remove answers from the prompt, leave question itself.
				for (const prompt of survey.prompts) {
					prompt.answers = [];
				}

				SetLoading(false);
			});
		}
	}

	return (
		<Container maxWidth="xl">
			<DataGrid
				rows={allSurveys || []}
				columns={columns}
				pageSize={10}
				rowsPerPageOptions={[10]}
				selectionModel={rowsSelected}
				autoHeight
				hideFooterSelectedRowCount
				className={classNames.dataGrid}
				getRowClassName={() => classNames.dataGridRow}
				onSelectionModelChange={(newRowSelected) => {
					setRowsSelected(newRowSelected);
				}}
			/>
			<br />
			<Button onClick={addSurvey} className={classNames.button}>
				<Add color="primary" />
				&nbsp; Add
			</Button>
			<Button
				onClick={editSurvey}
				disabled={!rowsSelected.length}
				className={classNames.button}
			>
				<Edit color="primary" />
				&nbsp; Edit
			</Button>
			<Button
				onClick={cloneSurvey}
				disabled={!rowsSelected.length}
				className={classNames.button}
			>
				<FileCopy color="primary" />
				&nbsp; Clone
			</Button>
		</Container>
	);
}

function SurveySelector(props) {
	return connect(<SurveySelectorC />, propKeys, props);
}

export default SurveySelector;
