import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { EventFormat } from '../../../../common/types/EventFormat';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { Colors } from '../../../../styles/Colors';
import DragAndDropHelper from '../../../../common/helpers/dragAndDropHelper';


const useStyles = makeStyles((theme) => ({
    draggable: {
        background: Colors.panel,
        borderRadius: 8,
        cursor: 'grab',
        padding: 8,
        margin: 4
    },
    droppable: {
        background: Colors.panelLight,
        color: Colors.panelLighterColor,
        borderRadius: 8,
        cursor: 'not-allowed',
        padding: 8,
        margin: 4
    },
    droppableDefined: {
        background: Colors.panel,
        color: Colors.pickstop,
        borderRadius: 8,
        cursor: 'not-allowed',
        padding: 8,
        margin: 4
    },
    hover: {
        background: Colors.pickstopGreen,
        color: Colors.panel
    },
    subDrop: {
        marginLeft: 12
    }
}));

/**
 * @typedef {Object} EventMapperProps
 * @property {any} details
 * @property {EventFormat} format
 */

/**
 * 
 * @param {EventMapperProps} param0
 * @returns
 */
export default function EventMapper({ details, format, onCreate }) {
    const classes = useStyles();
    let stageData = JSON.parse(format.json);
    const [state, setState] = React.useState({ stages: {} });

    React.useEffect(() => {
        if (Object.keys(state?.stages ?? {}).length !== stageData.stages.length) {
            let newState = {
                stages: {

                }
            };

            for (let i = 0; i < stageData.stages.length; i++) {
                newState.stages[i] = {
                    liquipediaPageId: 0,
                    liquipediaPage: '',
                    matchMappingKeys: [],
                    standingsMappingKeys: []
                }
            }

            setState(newState);
        }

    }, [details, format]);

    const onDragStart = (isMatch, stage, group) => {
        DragAndDropHelper.onDragStart({
            isMatch,
            stage,
            group
        });
    }

    const addToMapping = (list, key, index) => {
        while (list.length < index + 1) {
            list.push(null);
        }

        list[index] = key;
    }

    const onDrop = (stageIndex, substageIndex) => {
        const { isMatch, stage, group } = DragAndDropHelper.getDragData();

        const formatStage = stageData.stages[stageIndex];
        const targetMapping = state.stages[stageIndex];
        const elementId = `${group.id} - ${group.name}`;

        if (targetMapping.liquipediaPageId !== stage.id) {
            targetMapping.liquipediaPageId = stage.id;
            targetMapping.liquipediaPage = stage.name;

            targetMapping.matchMappingKeys = [];
            targetMapping.standingsMappingKeys = [];
        }
        
        // Group Stage
        if (formatStage.type === 1) {
            if (formatStage.groupStageDetails?.hasGroupBrackets) {
                if (isMatch) {
                    addToMapping(targetMapping.matchMappingKeys, {
                        id: group.name,
                        elementId,
                        displayName: group.name
                    }, substageIndex)
                }
            }
            else {
                if (isMatch) {
                    addToMapping(targetMapping.matchMappingKeys, {
                        id: group.name,
                        elementId,
                        displayName: group.name
                    }, substageIndex)
                }
                else {
                    addToMapping(targetMapping.standingsMappingKeys, {
                        id: group.id,
                        elementId,
                        displayName: group.name
                    }, substageIndex)
                }
            }
        }
        // Swiss Stage
        else if (formatStage.type === 2) {
            if (isMatch) {
                addToMapping(targetMapping.matchMappingKeys, {
                    id: group.name,
                    elementId,
                    displayName: group.name
                }, substageIndex)
            }
            else {
                addToMapping(targetMapping.standingsMappingKeys, {
                    id: group.id,
                    elementId,
                    displayName: group.name
                }, substageIndex)
            }
        }
        else {
            if (isMatch) {
                addToMapping(targetMapping.matchMappingKeys, {
                    id: group.name,
                    elementId,
                    displayName: group.name
                }, substageIndex)
            }
        }

        setState({...state});
    }

    const onReset = () => {
        let newState = {
            stages: {

            }
        };

        for (let i = 0; i < stageData.stages.length; i++) {
            newState.stages[i] = {
                liquipediaPageId: 0,
                liquipediaPage: '',
                matchMappingKeys: [],
                standingsMappingKeys: []
            }
        }

        setState(newState);
    }

    const onHover = (isMatch, i, j) => {
        const targetMapping = state.stages[i];
        let elementId = null;

        if (targetMapping) {
            elementId = (isMatch ? targetMapping.matchMappingKeys[j] : targetMapping.standingsMappingKeys[j])?.elementId ?? null
        }

        if (elementId) {
            document.getElementById(elementId).classList.add(classes.hover)
        }
    }

    const onMouseLeave = (isMatch, i, j) => {
        const targetMapping = state.stages[i];
        let elementId = null;

        if (targetMapping) {
            elementId = (isMatch ? targetMapping.matchMappingKeys[j] : targetMapping.standingsMappingKeys[j])?.elementId ?? null
        }

        if (elementId) {
            document.getElementById(elementId).classList.remove(classes.hover);
        }
    }

    let disableCreateEvent = false;
    const eventStagesMapped = stageData.stages.map((stage, i) => {
        const subStages = state?.stages[i];
        let subStageCount = 1;

        // Group Stage
        if (stage.type === 1) {
            subStageCount = stage.groupStageDetails?.groupCount ?? 1;
        }
        // Swiss or Playoffs
        else if (stage.type === 2 || stage.type === 3) {
            subStageCount = 1;
        }

        const stageElements = [];
        for (let j = 0; j < subStageCount; j++) {
            let defined = !!(subStages?.standingsMappingKeys[j]?.displayName ?? subStages?.matchMappingKeys[j]?.displayName);
            const isMatch = !((stage.type === 1 && !stage.groupStageDetails?.hasGroupBrackets) || stage.type === 2);
            let stageDisplayName = subStages?.standingsMappingKeys[j]?.displayName ?? subStages?.matchMappingKeys[j]?.displayName ?? 'Open';
            stageElements.push(
                <div className={defined ? classes.droppableDefined : classes.droppable}
                    onDragOver={(e) => { e.preventDefault() }}
                    onDrop={() => onDrop(i, j)}
                    onMouseOver={() => onHover(isMatch, i, j)}
                    onMouseLeave={() => onMouseLeave(isMatch, i, j)}>
                    {stageDisplayName}
                </div>)

            if (!defined) {
                disableCreateEvent = true;
            }

            // Need to specify match for the standing table
            if ((stage.type === 1 && !stage.groupStageDetails?.hasGroupBrackets) || stage.type === 2) {
                defined = !!(subStages?.matchMappingKeys[j]?.displayName);
                stageElements.push(
                    <div className={(defined ? classes.droppableDefined : classes.droppable) + ' ' + classes.subDrop}
                        onDragOver={(e) => { e.preventDefault() }}
                        onDrop={() => onDrop(i, j)}
                        onMouseOver={() => onHover(true, i, j)}
                        onMouseLeave={() => onMouseLeave(true, i, j)}>
                        {subStages?.matchMappingKeys[j]?.displayName ?? 'Select match group for determining start time'}
                    </div>)

                if (!defined) {
                    disableCreateEvent = true;
                }
            }
        }

        return <div>
            <label>{stage.name}</label>
            {stageElements}
        </div>
    });

    return <>
        <Grid container>
            <Grid item xs={5}>
                <h1>Event Stages</h1>
                {
                    eventStagesMapped
                }
                <div></div>
            </Grid>
            <Grid item xs={7}><h1>API Stages</h1>
                {
                    details.stageDetails.map((stage) => {
                        return <div>
                            <label><a target="_blank" href={stage.link} rel="noreferrer">{stage.name}</a></label>
                            {
                                stage.standingGroups.map((group) => {
                                    return <div className={classes.draggable} id={`${group.id} - ${group.name}`} draggable="true" onDragStart={() => onDragStart(false, stage, group)}>{group.name} - Standings</div>
                                })
                            }
                            {
                                stage.matchGroups.map((group) => {
                                    return <div className={classes.draggable} id={`${group.id} - ${group.name}`} draggable="true" onDragStart={() => onDragStart(true, stage,group)}>{group.name} - Bracket</div>
                                })
                            }
                        </div>
                    })
                }
            </Grid>
        </Grid>
        <Button color="primary" onClick={onReset}>Reset Mappings</Button>
        <Button color="secondary" onClick={() => { onCreate(state); }} disabled={disableCreateEvent}>Create Event</Button>
    </>
}