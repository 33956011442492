export default class DragAndDropHelper {

    static data;

    static onDragStart(data) {
        this.data = data;
    }

    static getDragData() {
        const toReturn = this.data;
        this.data = null;
        return toReturn;
    }

}