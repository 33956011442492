import CustomModal from "common/components/CustomModal/CustomModal";
import {
    EventFormatBracket,
    EventFormatBracketColumnMatch,
} from "common/helpers/eventFormatHelper";
import { MatchPick } from "common/types/MatchPick";
import { Match } from "bracket-system/types";
import ModalHelper from "common/helpers/modalHelper";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import * as React from "react";
import { ModalPanelTransparent } from "../CustomModal/Modal/ModalFrame";
import "./TeamSelection.scss";
import {
    findBracketMatchById,
    findColumnMatchById,
    getBracketColumnByTarget,
    getBracketMatchIdByTarget,
} from "common/helpers/bracketHelper";

/**
 * @typedef TeamSelectionProps
 * @type {object}
 * @property {Match} match
 * @property {any} eventData
 * @property {EventFormatBracket} bracket
 * @property {MatchPick[]} userBracketPicks
 * @property {boolean} selectedTop
 * @property {number} eventSubstageId
 * @property {boolean} useSeriesScore
 */

/**
 * @param {TeamSelectionProps} props
 */
export default function TeamSelection({
    match,
    eventData,
    bracket,
    userBracketPicks,
    selectedTop,
    eventId,
    eventStageId,
    eventSubstageId,
    useSeriesScore,
}) {
    const [winnerTeamId, setWinnerTeamId] = React.useState(
        (selectedTop ? match.participants[0] : match.participants[1]).id
    );
    const [loserTeamId, setLoserTeamId] = React.useState(
        (selectedTop ? match.participants[1] : match.participants[0]).id
    );

    const columnMatch = findColumnMatchById(bracket, match.id);
    const teams = eventData.teams;

    /** @type {MatchPick} */
    const currentPick = getUserPickFromBracketTarget(
        columnMatch,
        userBracketPicks,
        eventId,
        eventStageId,
        eventSubstageId,
        match.participants[0].id,
        match.participants[1].id
    );

    const winnerTeam = teams.find((t) => t.id === winnerTeamId);
    const loserTeam = teams.find((t) => t.id === loserTeamId);

    const scoreSelect = (winScore, loseScore) => {
        currentPick.winnerTeamId = winnerTeamId;
        currentPick.loserTeamId = loserTeamId;

        if (winnerTeamId === currentPick.topTeamId) {
            currentPick.topTeamScore = winScore;
            currentPick.bottomTeamScore = loseScore;
        } else {
            currentPick.topTeamScore = loseScore;
            currentPick.bottomTeamScore = winScore;
        }

        // Update next match
        if (match.nextMatchId) {
            const nextMatch = findColumnMatchById(bracket, match.nextMatchId);
            const nextMatchPick = getUserPickFromBracketTarget(
                nextMatch,
                userBracketPicks,
                eventId,
                eventStageId,
                eventSubstageId
            );

            if (matchIsOnTop(bracket, columnMatch, nextMatch)) {
                nextMatchPick.topTeamId = winnerTeamId;

                nextMatchPick.winnerTeamId = null;
                nextMatchPick.loserTeamId = null;
                nextMatchPick.topTeamScore = 0;
                nextMatchPick.bottomTeamScore = 0;
            } else {
                nextMatchPick.bottomTeamId = winnerTeamId;

                nextMatchPick.winnerTeamId = null;
                nextMatchPick.loserTeamId = null;
                nextMatchPick.topTeamScore = 0;
                nextMatchPick.bottomTeamScore = 0;
            }

            wipeOutFurtherPicks(nextMatch);
        }

        if (match.nextLooserMatchId) {
            const loserMatch = findColumnMatchById(
                bracket,
                match.nextLooserMatchId
            );
            const loserMatchPick = getUserPickFromBracketTarget(
                loserMatch,
                userBracketPicks,
                eventId,
                eventStageId,
                eventSubstageId
            );

            if (matchIsOnTop(bracket, columnMatch, loserMatch)) {
                loserMatchPick.topTeamId = loserTeamId;

                loserMatchPick.winnerTeamId = null;
                loserMatchPick.loserTeamId = null;
                loserMatchPick.topTeamScore = 0;
                loserMatchPick.bottomTeamScore = 0;
            } else {
                loserMatchPick.bottomTeamId = loserTeamId;

                loserMatchPick.winnerTeamId = null;
                loserMatchPick.loserTeamId = null;
                loserMatchPick.topTeamScore = 0;
                loserMatchPick.bottomTeamScore = 0;
            }

            wipeOutFurtherPicks(loserMatch);
        }

        Store.set(StoreKeys.EVENT.USER.BRACKET_PICKS, userBracketPicks);
        close();
    };

    /**
     *
     * @param {EventFormatBracketColumnMatch} match
     */
    const wipeOutFurtherPicks = (match) => {
        const bracketMatchId = getBracketMatchIdByTarget(bracket, {
            part: match.part,
            col: match.col,
            colIndex: match.colIndex,
        });
        const bracketMatch = findBracketMatchById(bracket, bracketMatchId);

        // Update next match
        if (bracketMatch.nextMatchId) {
            const nextMatch = findColumnMatchById(
                bracket,
                bracketMatch.nextMatchId
            );
            const nextMatchPick = getUserPickFromBracketTarget(
                nextMatch,
                userBracketPicks,
                eventId,
                eventStageId,
                eventSubstageId
            );

            if (matchIsOnTop(bracket, match, nextMatch)) {
                nextMatchPick.topTeamId = null;

                nextMatchPick.winnerTeamId = null;
                nextMatchPick.loserTeamId = null;
                nextMatchPick.topTeamScore = 0;
                nextMatchPick.bottomTeamScore = 0;
            } else {
                nextMatchPick.bottomTeamId = null;

                nextMatchPick.winnerTeamId = null;
                nextMatchPick.loserTeamId = null;
                nextMatchPick.topTeamScore = 0;
                nextMatchPick.bottomTeamScore = 0;
            }

            wipeOutFurtherPicks(nextMatch);
        }

        if (bracketMatch.nextLooserMatchId) {
            const loserMatch = findColumnMatchById(
                bracket,
                bracketMatch.nextLooserMatchId
            );
            const loserMatchPick = getUserPickFromBracketTarget(
                loserMatch,
                userBracketPicks,
                eventId,
                eventStageId,
                eventSubstageId
            );

            if (matchIsOnTop(bracket, match, loserMatch)) {
                loserMatchPick.topTeamId = null;

                loserMatchPick.winnerTeamId = null;
                loserMatchPick.loserTeamId = null;
                loserMatchPick.topTeamScore = 0;
                loserMatchPick.bottomTeamScore = 0;
            } else {
                loserMatchPick.bottomTeamId = null;

                loserMatchPick.winnerTeamId = null;
                loserMatchPick.loserTeamId = null;
                loserMatchPick.topTeamScore = 0;
                loserMatchPick.bottomTeamScore = 0;
            }

            wipeOutFurtherPicks(loserMatch);
        }
    };

    const close = () => {
        ModalHelper.closeAllModals();
    };

    const selectWinner = (winner, loser) => {
        setWinnerTeamId(winner.id);
        setLoserTeamId(loser.id);
    };

    const bracketColumn = getBracketColumnByTarget(bracket, {
        part: columnMatch.part,
        col: columnMatch.col,
    });

    // If BestOf is set to 1, remove the second click needed
    if (!useSeriesScore || bracketColumn.bestOf === 1) {
        scoreSelect(1, 0);
        return <></>;
    }

    return (
        <>
            <CustomModal>
                <ModalPanelTransparent>
                    <div className="team-selection-modal">
                        <div className="teams">
                            <TeamBlock
                                team={winnerTeam}
                                onClick={() => {
                                    selectWinner(winnerTeam, loserTeam);
                                }}
                            />
                            <div className="win-vs">
                                <div>
                                    <label>
                                        {useSeriesScore ? "Win Vs" : "Or"}
                                    </label>
                                </div>
                            </div>
                            <TeamBlock
                                team={loserTeam}
                                onClick={() => {
                                    selectWinner(loserTeam, winnerTeam);
                                }}
                            />
                        </div>
                        {useSeriesScore && (
                            <>
                                <div className="prediction-label">
                                    <label>
                                        Predict Best Of {bracketColumn.bestOf}{" "}
                                        Series Score:
                                    </label>
                                </div>
                                <div className="score-selection">
                                    {bracketColumn.bestOf === 3 && (
                                        <div className="predictions">
                                            <div
                                                className="easy-win bestof3"
                                                onClick={() =>
                                                    scoreSelect(2, 0)
                                                }
                                            >
                                                <img
                                                    src={winnerTeam.logoUrl}
                                                    alt="Team Logo"
                                                />
                                                <label className="team-name">
                                                    {winnerTeam.name} win
                                                </label>
                                                <h3>2-0</h3>
                                            </div>
                                            <div
                                                className="tough-win bestof3"
                                                onClick={() =>
                                                    scoreSelect(2, 1)
                                                }
                                            >
                                                <img
                                                    src={winnerTeam.logoUrl}
                                                    alt="Team Logo"
                                                />
                                                <label className="team-name">
                                                    {winnerTeam.name} win
                                                </label>
                                                <h3>2-1</h3>
                                            </div>
                                        </div>
                                    )}
                                    {bracketColumn.bestOf === 5 && (
                                        <div className="predictions">
                                            <div
                                                className="easy-win bestof5"
                                                onClick={() =>
                                                    scoreSelect(3, 0)
                                                }
                                            >
                                                <img
                                                    src={winnerTeam.logoUrl}
                                                    alt="Team Logo"
                                                />
                                                <label className="team-name">
                                                    {winnerTeam.name} win
                                                </label>
                                                <h3>3-0</h3>
                                            </div>
                                            <div
                                                className="normal-win bestof5"
                                                onClick={() =>
                                                    scoreSelect(3, 1)
                                                }
                                            >
                                                <img
                                                    src={winnerTeam.logoUrl}
                                                    alt="Team Logo"
                                                />
                                                <label className="team-name">
                                                    {winnerTeam.name} win
                                                </label>
                                                <h3>3-1</h3>
                                            </div>
                                            <div
                                                className="tough-win"
                                                onClick={() =>
                                                    scoreSelect(3, 2)
                                                }
                                            >
                                                <img
                                                    src={winnerTeam.logoUrl}
                                                    alt="Team Logo"
                                                />
                                                <label className="team-name">
                                                    {winnerTeam.name} win
                                                </label>
                                                <h3>3-2</h3>
                                            </div>
                                        </div>
                                    )}
                                    {bracketColumn.bestOf === 7 && (
                                        <div className="predictions">
                                            <div
                                                className="easy-win bestof7odd"
                                                onClick={() =>
                                                    scoreSelect(4, 0)
                                                }
                                            >
                                                <img
                                                    src={winnerTeam.logoUrl}
                                                    alt="Team Logo"
                                                />
                                                <label className="team-name">
                                                    {winnerTeam.name} win
                                                </label>
                                                <h3>4-0</h3>
                                            </div>
                                            <div
                                                className="easy-normal-win bestof7even"
                                                onClick={() =>
                                                    scoreSelect(4, 1)
                                                }
                                            >
                                                <img
                                                    src={winnerTeam.logoUrl}
                                                    alt="Team Logo"
                                                />
                                                <label className="team-name">
                                                    {winnerTeam.name} win
                                                </label>
                                                <h3>4-1</h3>
                                            </div>
                                            <div
                                                className="normal-tough-win bestof7odd"
                                                onClick={() =>
                                                    scoreSelect(4, 2)
                                                }
                                            >
                                                <img
                                                    src={winnerTeam.logoUrl}
                                                    alt="Team Logo"
                                                />
                                                <label className="team-name">
                                                    {winnerTeam.name} win
                                                </label>
                                                <h3>4-2</h3>
                                            </div>
                                            <div
                                                className="tough-win"
                                                onClick={() =>
                                                    scoreSelect(4, 3)
                                                }
                                            >
                                                <img
                                                    src={winnerTeam.logoUrl}
                                                    alt="Team Logo"
                                                />
                                                <label className="team-name">
                                                    {winnerTeam.name} win
                                                </label>
                                                <h3>4-3</h3>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </ModalPanelTransparent>
            </CustomModal>
        </>
    );
}

function TeamBlock(props) {
    const { team, noClick, onClick } = props;

    const isPlayerOrCaptain = (member) => {
        return member.position === "Player" || member.position === "Captain";
    };

    const renderTeamPlayers = (team) => {
        const players = team.members.filter((player) =>
            isPlayerOrCaptain(player)
        );
        const coach = team.members.filter(
            (player) => player.position === "Coach"
        );
        const other = team.members.filter(
            (player) =>
                player.position !== "Player" &&
                player.position !== "Coach" &&
                player.position !== "Captain"
        );
        const playerOrder = [...players, ...coach, ...other];

        return playerOrder.map((member, index) => {
            return (
                <tr key={index}>
                    <td>
                        {isPlayerOrCaptain(member) ? (
                            <span>{index + 1}</span>
                        ) : (
                            ""
                        )}
                        {!isPlayerOrCaptain(member) ? (
                            <span title={member.position}>
                                {member.position.substring(0, 1)}
                            </span>
                        ) : (
                            ""
                        )}
                    </td>
                    <td>
                        <label>
                            {member.flag?.imageUrl && (
                                <img src={member.flag.imageUrl} alt="Flag" />
                            )}
                            <span>{member.username}</span>
                        </label>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div
            className="team-details"
            onClick={!noClick && onClick}
            style={{ cursor: noClick ? "default" : "pointer" }}
        >
            <div className="team-info">
                <img src={team.logoUrl} alt="Team Logo" />
                <label className="team-name">{team.name}</label>{" "}
            </div>
            <div className="team-players">
                <table>
                    <tbody>{renderTeamPlayers(team)}</tbody>
                </table>
            </div>
        </div>
    );
}

/**
 *
 * @param {EventFormatBracket} bracket
 * @param {EventFormatBracketColumnMatch} currentMatch
 * @param {EventFormatBracketColumnMatch} nextMatch
 */
function matchIsOnTop(bracket, currentMatch, nextMatch) {
    if (bracket.freeform) {
        const indexOfCurrentPart = bracket.freeformParts.findIndex(
            (part) => part.identifier == currentMatch.part
        );
        const indexOfNextPart = bracket.freeformParts.findIndex(
            (part) => part.identifier == nextMatch.part
        );

        if (indexOfCurrentPart < indexOfNextPart) {
            return currentMatch.loserPath.isTop;
        } else if (indexOfCurrentPart == indexOfNextPart) {
            const currentPart = bracket.freeformParts[indexOfCurrentPart];
            const currentColumn = currentPart.columns[currentMatch.col];
            const nextColumn = currentPart.columns[nextMatch.col];

            if (currentColumn.matchCount === nextColumn.matchCount) {
                return false;
            } else {
                return currentMatch.colIndex % 2 === 0;
            }
        } else if (indexOfCurrentPart > indexOfNextPart) {
            return false;
        }
    } else {
        // Dropdown Loser scenario or Upper finals into Grand Final
        if (currentMatch.part === "UB" && nextMatch.part === "GF") {
            return true;
        }

        if (currentMatch.part === "UB" && nextMatch.part === "LB") {
            return currentMatch.loserPath.isTop;
        }

        // Lower bracket to GF is on bottom.
        if (currentMatch.part === "LB" && nextMatch.part === "GF") {
            return false;
        }

        if (currentMatch.part === "UB" && nextMatch.part === "UB") {
            const upperBracket = bracket.parts.find(
                (p) => p.identifier === "UB"
            );
            const currentColumn = upperBracket.columns[currentMatch.col];
            const nextColumn = upperBracket.columns[nextMatch.col];
            // If the match count is the same, then the next match is a dropdown and the winner is in the bottom of the next match.
            if (currentColumn.matchCount === nextColumn.matchCount) {
                return false;
            } else {
                return currentMatch.colIndex % 2 === 0;
            }
        }

        if (currentMatch.part === "LB" && nextMatch.part === "LB") {
            const lowerBracket = bracket.parts.find(
                (p) => p.identifier === "LB"
            );
            const currentColumn = lowerBracket.columns[currentMatch.col];
            const nextColumn = lowerBracket.columns[nextMatch.col];
            // If the match count is the same, then the next match is a dropdown and the winner is in the bottom of the next match.
            if (currentColumn.matchCount === nextColumn.matchCount) {
                return false;
            } else {
                return currentMatch.colIndex % 2 === 0;
            }
        }

        const upperBracket = bracket.parts.find((p) => p.identifier === "UB");
        const currentColumn = upperBracket.columns[currentMatch.col];
        const nextColumn = upperBracket.columns[nextMatch.col];
        // If the match count is the same, then the next match is a dropdown and the winner is in the bottom of the next match.
        if (currentColumn.matchCount === nextColumn.matchCount) {
            return false;
        } else {
            return currentMatch.colIndex % 2 === 0;
        }
    }
}

function getUserPickFromBracketTarget(
    columnMatch,
    userBracketPicks,
    eventId,
    eventStageId,
    eventSubstageId,
    topTeamId,
    bottomTeamId
) {
    const previousPick = userBracketPicks.find(
        (p) =>
            p.bracketPart === columnMatch.part &&
            p.bracketCol === columnMatch.col &&
            p.bracketColIndex === columnMatch.colIndex &&
            p.eventSubstageId === eventSubstageId
    );
    const isAddingPick = !previousPick;

    /** @type {MatchPick} */
    const currentPick = isAddingPick
        ? {
              bracketPart: columnMatch.part,
              bracketCol: columnMatch.col,
              bracketColIndex: columnMatch.colIndex,
              topTeamId,
              topTeamScore: 0,
              bottomTeamId,
              bottomTeamScore: 0,
              eventId,
              eventStageId,
              eventSubstageId,
              winnerTeamId: null,
              loserTeamId: null,
          }
        : previousPick;

    if (isAddingPick) {
        userBracketPicks.push(currentPick);
    }

    return currentPick;
}
