const StoreKeysType = {
	ADMIN: {
		GIVE_TROPHY: {
			EVENTS: "",
		},
		PICKS: {
			EVENTS: "",
			BRACKET_PICKS: "",
			GROUP_PICKS: "",
		},
		SURVEYS: {
			ALL: "",
			CURRENT: "",
		},
	},
	EVENT: {
		EVENTDATA: "",
		FORMAT: "",
		STAGE_INDEX: "",
		SUBSTAGE_INDEX: "",
		STEP: "",
		SELECTEDMATCH: "",
		USER: {
			BRACKET_PICKS: "",
			GROUP_PICKS: "",
		},
		SCORE: "",
		BRACKET_USER: "",
		HAS_BRACKET: "",
		IS_EDITING: "",
		LEADERBOARD: "",
		OFFICIAL: {
			BRACKET_PICKS: "",
			GROUP_PICKS: "",
		},
		SHOW_OFFICIAL: "",
		SHOW_PLAYERS: "",
	},
	EVENTCREATOR: {
		CREATE_DATA: "",
		EVENT_DATA: "",
		EVENT_DETAILS: "",
		EVENT_FORMATS: "",
		GAMES: "",
		SELECTED_EVENT: "",
		UPCOMING_EVENTS: "",
	},
	EVENTFORMATBUILDER: {
		BINDING_MATCH: "",
		CURRENT_FORMAT: "",
		CURRENT_SCORING_GROUP: "",
		CURRENT_VIEW: "",
		EVENT_FORMATS: "",
	},
	GAMES: "",
	IS_LOADING: "",
	LEAGUES: {
		ANALYST: {
			LEAGUE: "",
			EVENTS: "",
			RANKINGS: "",
			VIEW_TYPE: "",
		},
		VIEWING: {
			LEAGUE: "",
			EVENTS: "",
			RANKINGS: "",
			VIEW_TYPE: "",
		},
		JOINED: "",
		LIMIT: "",
		NEW: "",
		POPULAR: "",
		NAV_VIEW_TYPE: "",
	},
	LeftPane: {
		Open: "",
		PastEventIds: "",
		PastEventIndex: "",
		ViewingPastEvents: "",
	},
	LOADING_TEXT: "",
	LOGIN_PROVIDER: "",
	ROLES: "",
	USER: {
		LOADED: "",
		LOGGED_IN: "",
		ID: "",
		USERNAME: "",
	},
	USERPROFILE: {
		OPEN: "",
		USER: "",
	},
	SETTINGS: {
		LOADED: "",
		TROPHY_ROOM_ENABLED: "",
	},
	// User Survey Screen
	SURVEY: {
		CURRENT: "", // The current survey
		PICKS: "", // User picks
	},
	NOTIFICATIONS: {
		LIST: "",
		READ: "",
	},
};

/** @type {StoreKeysType} */
const StoreKeys = automapKeys(null, StoreKeysType);

/**
 *
 * @param {string} rootPath
 * @param {StoreKeysType} keysObj
 * @returns {StoreKeysType} storekeys
 */
function automapKeys(rootPath, keysObj) {
	const result = JSON.parse(JSON.stringify(keysObj));

	for (const key in result) {
		const relativePath = rootPath ? rootPath + "-" + key : key;
		const keyObj = result[key];
		if (typeof keyObj == "object") {
			result[key] = automapKeys(relativePath, result[key]);
		} else {
			result[key] = relativePath;
		}
	}

	return result;
}

export default StoreKeys;
