import { GamesEnum } from './Games';

export const RewardTypes = {
	Medal: 1,
	Trophy: 2,
	Globe: 3,
	Cologne: 4,
	Katowice: 5,

	getRewardTypes: (gameId) => {
		if (gameId === GamesEnum.CounterStrike) {
			return {
				Medal: 1,
				Trophy: 2,
				Globe: 3,
				Cologne: 4,
				Katowice: 5,
			}
		}
		else {
			return {
				Medal: 1,
				Trophy: 2,
				Globe: 3,
			}
		}
	}
};
