import * as React from "react";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import DoubleEliminationBracket from "bracket-system/bracket-double/double-elim-bracket";
import { EventStageTypes } from "common/constants/EventStageTypes";
import { Colors } from "styles/Colors";
import Match from "bracket-system/components/match";
import SvgViewer from "bracket-system/svg-viewer";
import { parsePicksIntoBracketMatches } from "common/helpers/bracketHelper";
import {
    // eslint-disable-next-line no-unused-vars
    EventFormatData,
    getHeaderNamesFromBracket,
    getLockedColumns,
} from "common/helpers/eventFormatHelper";
import BracketEditor from "./BracketEditor";
// eslint-disable-next-line no-unused-vars
import { EventStage } from "common/types/EventStage";
import UserManager from "common/userManager";
import FreeformBracket from "bracket-system/bracket-freeform/freeform-bracket";

const useStyles = makeStyles((theme) => {
    return {
        bracketWrapper: {
            border: `2px solid ${Colors.panel}`,
            borderRadius: theme.shape.borderRadius,
            overflow: "hidden",
        },
    };
});

const propKeys = {
    eventData: StoreKeys.EVENT.EVENTDATA,
    format: StoreKeys.EVENT.FORMAT,
    userBracketPicks: StoreKeys.EVENT.USER.BRACKET_PICKS,
    userGroupPicks: StoreKeys.EVENT.USER.GROUP_PICKS,
    officialBracketPicks: StoreKeys.EVENT.OFFICIAL.BRACKET_PICKS,
    officialGroupPicks: StoreKeys.EVENT.OFFICIAL.GROUP_PICKS,
    isEditing: StoreKeys.EVENT.IS_EDITING,
    stageIndex: StoreKeys.EVENT.STAGE_INDEX,
    substageIndex: StoreKeys.EVENT.SUBSTAGE_INDEX,
    showOfficial: StoreKeys.EVENT.SHOW_OFFICIAL,
};
/**
 * @typedef BracketRendererCProps
 * @type {object}
 * @property {any} eventData
 * @property {EventFormatData} format
 * @property {MatchPick[]} userBracketPicks
 * @property {MatchPick[]} officialBracketPicks
 * @property {boolean} isEditing
 * @property {number} width
 * @property {number} stageIndex
 * @property {number} substageIndex
 * @property {boolean} showOfficial
 */

/**
 * @param {BracketRendererCProps} props
 */
function BracketRendererC({
    eventData,
    format,
    userBracketPicks,
    officialBracketPicks,
    isEditing,
    width,
    stageIndex,
    substageIndex,
    showOfficial,
}) {
    const ref = React.useRef();
    const styles = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const bracketWidth = !isSmall
        ? window.innerWidth - 382
        : window.innerWidth - 24;

    let baseUIHeight = isSmall ? 334 : 320;
    const bottomNavHeight = 62;
    const loggedIn = UserManager.isLoggedIn();
    let otherUIHeight = isSmall ? baseUIHeight + bottomNavHeight : baseUIHeight;
    if (loggedIn) otherUIHeight += 39;
    const renderHeight = Math.max(window.innerHeight - otherUIHeight, 200);

    const eventNameLabel = document.getElementById("EventNameLabel");
    baseUIHeight += eventNameLabel?.clientHeight - 23;

    const currentStage = format.stages[stageIndex];
    /** @type {EventStage} */
    const currentEventStage = eventData.eventStages[stageIndex];
    const currentSubstage = currentEventStage.eventSubstage[substageIndex];
    const headerNames = getHeaderNamesFromBracket(currentStage.bracket);
    const lockedColumns = getLockedColumns(currentStage.bracket);
    const useSeriesScore =
        currentStage?.type === EventStageTypes.Playoffs
            ? currentStage?.playoffDetails?.useSeriesScore
            : currentStage?.groupStageDetails?.useSeriesScore;

    const officialMatches = parsePicksIntoBracketMatches(
        eventData,
        currentEventStage,
        currentSubstage,
        currentStage,
        currentStage.bracket,
        [],
        officialBracketPicks
    );

    const matches = parsePicksIntoBracketMatches(
        eventData,
        currentEventStage,
        currentSubstage,
        currentStage,
        currentStage.bracket,
        userBracketPicks ?? [],
        officialBracketPicks
    );

    return (
        <>
            {isEditing && <BracketEditor />}
            {!isEditing && (
                <>
                    {currentStage.bracket.freeform && (
                        <div className={styles.bracketWrapper} ref={ref}>
                            <FreeformBracket
                                parts={currentStage.bracket.freeformParts}
                                matches={
                                    showOfficial ? officialMatches : matches
                                }
                                officialMatches={
                                    showOfficial ? [] : officialMatches
                                }
                                matchComponent={Match}
                                options={{
                                    style: {
                                        roundHeader: {
                                            fontSize: 20,
                                            backgroundColor: Colors.panel,
                                            fontColor: Colors.primary,
                                        },
                                        connectorColor: Colors.panel,
                                        connectorColorHighlight:
                                            Colors.pickstop,
                                    },
                                }}
                                svgWrapper={({ children, ...props }) => (
                                    <SvgViewer
                                        width={
                                            ref.current?.offsetWidth ??
                                            bracketWidth ??
                                            width
                                        }
                                        height={renderHeight}
                                        {...props}
                                    >
                                        {children}
                                    </SvgViewer>
                                )}
                                hideSeriesScore={!useSeriesScore}
                            />
                        </div>
                    )}
                    {!currentStage.bracket.freeform && (
                        <div className={styles.bracketWrapper} ref={ref}>
                            <DoubleEliminationBracket
                                matches={
                                    showOfficial ? officialMatches : matches
                                }
                                officialMatches={
                                    showOfficial ? [] : officialMatches
                                }
                                matchComponent={Match}
                                options={{
                                    style: {
                                        roundHeader: {
                                            fontSize: 20,
                                            backgroundColor: Colors.panel,
                                            fontColor: Colors.primary,
                                        },
                                        connectorColor: Colors.panel,
                                        connectorColorHighlight:
                                            Colors.pickstop,
                                    },
                                }}
                                svgWrapper={({ children, ...props }) => (
                                    <SvgViewer
                                        width={
                                            ref.current?.offsetWidth ??
                                            bracketWidth ??
                                            width
                                        }
                                        height={renderHeight}
                                        {...props}
                                    >
                                        {children}
                                    </SvgViewer>
                                )}
                                customHeaderNames={headerNames}
                                lockedColumns={lockedColumns}
                                hideSeriesScore={!useSeriesScore}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default function BracketRenderer(props) {
    return connect(<BracketRendererC />, propKeys, props);
}
