import axiosWrapper from "common/axiosWrapper";
import { toMMDDYYYY } from "common/helpers/dateHelper";
// eslint-disable-next-line no-unused-vars
import { EventCreateRequestDto } from "../common/types/EventCreateRequestDto";
// eslint-disable-next-line no-unused-vars
import { SharedTournamentDto } from "common/types/SharedTournamentDto";

const baseUrl = "/api/liquipediaEventBuilder";

/** The Event service which calls the API endpoints for the Event Builder Controller */
export default class EventBuilderService {
	/**
	 * Gets a list of upcoming events.
	 * @returns {Promise<Array<SharedTournamentDto>>} the upcoming Abios Tournaments within 30 days from current time.
	 */
	static async getUpcomingEvents(gameId, from, to) {
		return await axiosWrapper.get(
			`${baseUrl}/upcoming?gameId=${gameId}&from=${toMMDDYYYY(
				from
			)}&to=${toMMDDYYYY(to)}`
		);
	}

	/**
	 * Create an event with the provided data.
	 *
	 * @param {EventCreateRequestDto} createRequest
	 * @returns {Promise<any>} an EventDto object.
	 */
	static async createEvent(createRequest) {
		return await axiosWrapper.post(`${baseUrl}/create`, createRequest);
	}

	/**
	 * Gets the event details.
	 *
	 * @returns {Promise<any>} an NewEventDetailsDto object.
	 */
	static async getEventDetails(gameId, pageId) {
		return await axiosWrapper.get(`${baseUrl}/getDetails?gameId=${gameId}&pageId=${pageId}`);
	}
}
