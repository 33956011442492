import React from "react";
import MatchWrapper from "bracket-system/core/match-wrapper";
import { getPreviousMatches } from "bracket-system/core/match-functions";
import { FreeformBracketPartProps } from "bracket-system/types";
import FreeformBracketRoundHeader from "./freeform-bracket-round-header";
import FreeformConnectorService from "./freeform-connector-service";

const FreeformBracketPart = ({
    identifier,
    columns,
    calculatedStyles,
    offsetTop = 0,
    matchComponent,
    hideSeriesScore,
    onPartClick,
    onColumnClick,
    onMatchClick,
    onPartyClick,
    canEdit,
}: FreeformBracketPartProps) => {
    const {
        canvasPadding,
        width,
        columnWidth,
        rowHeight,
        boxHeight,
        roundHeader,
    } = calculatedStyles;

    let x = canvasPadding;
    let y = canvasPadding + offsetTop;

    return (
        // @ts-ignore
        <g
            onClick={(e) => {
                if (FreeformConnectorService.ignoreClickEvent) {
                    return;
                }

                if (onPartClick) {
                    onPartClick(identifier);
                }
            }}
        >
            {columns.map((column) => {
                // Add column offset.
                x += column.paddingLeft;
                const columnIndex = column.index;

                const headerElement = roundHeader.isShown && (
                    <FreeformBracketRoundHeader
                        x={x}
                        y={y}
                        width={calculatedStyles.width}
                        roundHeaderStyle={roundHeader}
                        canvasPadding={canvasPadding}
                        onClick={(event) => {
                            if (onColumnClick) {
                                onColumnClick(identifier, columnIndex);
                                event.stopPropagation();
                            }
                        }}
                        headerText={column.name}
                    />
                );

                let matchX = x;
                let matchY =
                    y +
                    column.paddingTop +
                    (roundHeader.isShown
                        ? roundHeader.height + roundHeader.marginBottom
                        : 0);

                const matchElements = column.matches.map((match, rowIndex) => {
                    const previousBottomPosition = (rowIndex + 1) * 2 - 1;
                    const { previousTopMatch, previousBottomMatch } =
                        getPreviousMatches(
                            columnIndex,
                            columns,
                            previousBottomPosition
                        );

                    matchY += match.paddingTop;

                    FreeformConnectorService.setMatchConnectorLocation(
                        match.id.toString(),
                        "in",
                        matchX,
                        matchY + boxHeight / 2
                    );
                    FreeformConnectorService.setMatchConnectorLocation(
                        match.id.toString(),
                        "out",
                        matchX + width,
                        matchY + boxHeight / 2
                    );

                    const matchElement = (
                        <>
                            <g id={`match_${match.id.toString()}`}>
                                <MatchWrapper
                                    x={matchX}
                                    y={matchY}
                                    rowIndex={rowIndex}
                                    columnIndex={columnIndex}
                                    match={match}
                                    previousBottomMatch={previousBottomMatch}
                                    topText={match.startTime}
                                    bottomText={match.name}
                                    teams={match.participants}
                                    onMatchClick={(e) => {
                                        if (
                                            e.currentTarget?.classList.contains(
                                                "match-connector"
                                            )
                                        ) {
                                            return;
                                        }
                                        onMatchClick(e);
                                    }}
                                    onPartyClick={(
                                        e,
                                        match,
                                        isTop,
                                        party,
                                        partyWon
                                    ) => {
                                        if (
                                            e.currentTarget?.classList.contains(
                                                "match-connector"
                                            )
                                        ) {
                                            return;
                                        }
                                        if (onPartyClick && !column.isLocked) {
                                            onPartyClick(
                                                e,
                                                match,
                                                isTop,
                                                party,
                                                partyWon
                                            );
                                        }
                                    }}
                                    locked={column.isLocked}
                                    style={calculatedStyles}
                                    matchComponent={matchComponent}
                                    hideSeriesScore={hideSeriesScore}
                                    isQualifierColumn={column.isQualifier}
                                    canEdit={canEdit}
                                />
                            </g>
                            {canEdit && (
                                <>
                                    {columnIndex > 0 && (
                                        <>
                                            <ellipse
                                                id={`${match.id}${FreeformConnectorService.connectorTopInIndicator}`}
                                                cx={matchX}
                                                cy={matchY + boxHeight / 2 - 16}
                                                rx={8}
                                                ry={8}
                                                fill="#373737"
                                                stroke="rgb(255, 179, 0)"
                                                style={{ cursor: "pointer" }}
                                                className="match-connector"
                                            />
                                            <ellipse
                                                id={`${match.id}${FreeformConnectorService.connectorBottomInIndicator}`}
                                                cx={matchX}
                                                cy={matchY + boxHeight / 2 + 16}
                                                rx={8}
                                                ry={8}
                                                fill="#373737"
                                                stroke="rgb(255, 179, 0)"
                                                style={{ cursor: "pointer" }}
                                                className="match-connector"
                                            />
                                        </>
                                    )}
                                    <ellipse
                                        id={`${match.id}${FreeformConnectorService.connectorOutIndicator}`}
                                        cx={matchX + width}
                                        cy={matchY + boxHeight / 2}
                                        rx={8}
                                        ry={8}
                                        fill="#373737"
                                        stroke="rgb(255, 179, 0)"
                                        style={{ cursor: "pointer" }}
                                        className="match-connector"
                                    />
                                </>
                            )}
                        </>
                    );

                    matchY += rowHeight;

                    return matchElement;
                });

                x += columnWidth;

                return (
                    <>
                        {headerElement}
                        {matchElements}
                    </>
                );
            })}
        </g>
    );
};
export default FreeformBracketPart;
