import { FreeformBracketPart, FreeformMatch } from "bracket-system/types";
import { toast } from "react-toastify";

class Position {
    x: number;
    y: number;
}

class FreeformConnectorService {
    connectorOutIndicator = "_WINNER_OUT";
    connectorTopInIndicator = "_TOP_IN";
    connectorBottomInIndicator = "_BOTTOM_IN";

    startPoint: HTMLElement;
    ignoreClickEvent: boolean;
    freeformBracket: FreeformBracketPart[];

    matchConnectors = new Map<string, Position>();
    onUpdate: (freeformBracket: FreeformBracketPart[]) => void;

    setFreeformBracket(freeformBracket: FreeformBracketPart[]) {
        this.freeformBracket = freeformBracket;
    }

    getMatchConnectorLocation(
        matchId: string | number,
        direction: "in" | "out"
    ): Position {
        return this.matchConnectors.get(`${matchId}_${direction}`);
    }

    setMatchConnectorLocation(
        matchId: string | number,
        direction: "in" | "out",
        x: number,
        y: number
    ) {
        this.matchConnectors.set(`${matchId}_${direction}`, { x, y });
    }

    startDrag(e: React.MouseEvent<SVGElement, MouseEvent>) {
        const target = e.target as HTMLElement;
        if (
            !target?.classList?.contains("match-connector") ||
            !target?.id.endsWith(this.connectorOutIndicator)
        ) {
            return;
        }

        this.startPoint = target;
        e.stopPropagation();
    }

    drag(e: React.MouseEvent<SVGElement, MouseEvent>) {
        if (this.startPoint) {
            e.stopPropagation();
        }
    }

    endDrag(e: React.MouseEvent<SVGElement, MouseEvent>) {
        if (!this.startPoint) {
            return;
        }

        const target = e.target as HTMLElement;
        if (
            !target?.classList?.contains("match-connector") ||
            (!target?.id.endsWith(this.connectorTopInIndicator) &&
                !target?.id.endsWith(this.connectorBottomInIndicator))
        ) {
            this.startPoint = null;
            return;
        }

        // do the connection between the points.
        this.connect(this.startPoint, target);

        this.ignoreClickEvent = true;
        e.stopPropagation();
        e.preventDefault();
        setTimeout(() => {
            this.ignoreClickEvent = false;
        });
    }

    connect(first: HTMLElement, second: HTMLElement) {
        if (!first || !second) {
            return;
        }

        const match = this.findMatch(
            first.id.replace(this.connectorOutIndicator, "")
        );
        const secondMatchId = second.id
            .replace(this.connectorTopInIndicator, "")
            .replace(this.connectorBottomInIndicator, "");
        const secondMatch = this.findMatch(secondMatchId);

        // NO VISUAL CONNECTION MADE, GIVE USER FEEDBACK
        if (match.part !== secondMatch.part) {
            toast("Connection made!", { type: "success" });
        }

        match.nextMatchId = secondMatchId;
        this.onUpdate(this.freeformBracket);
    }

    findMatch(matchId): FreeformMatch {
        for (const part of this.freeformBracket) {
            for (const col of part.columns) {
                const found = col.matches.find((m) => m.id === matchId);
                if (found) {
                    return found;
                }
            }
        }
    }

    blockDrag() {
        return !!this.startPoint;
    }

    setOnUpdateCallback(
        onUpdate: (freeformBracket: FreeformBracketPart[]) => void
    ) {
        this.onUpdate = onUpdate;
    }
}

const FreeformConnectorServiceInstance = new FreeformConnectorService();

export default FreeformConnectorServiceInstance;
