import {
    Container,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Typography,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    makeStyles,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Button,
    TextField,
} from "@material-ui/core";
import { SetLoading } from "common/components/Loader/Loader";
import { connect } from "common/connect";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import * as React from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import EventBuilderService from "services/EventBuilderService";
import LookupService from "services/LookupService";
import { RewardTypes } from "common/constants/RewardTypes";
import "./EventCreator.scss";
import { Colors } from "styles/Colors";
// eslint-disable-next-line no-unused-vars
import { EventCreateRequestDto } from "common/types/EventCreateRequestDto";
// eslint-disable-next-line no-unused-vars
import { Lookup } from "common/types/Lookup";
import EventFormatService from "services/EventFormatService";
import { toMMDDYYYY, toMonthNameDayAndYear } from "common/helpers/dateHelper";
// eslint-disable-next-line no-unused-vars
import { SharedTournamentDto } from "common/types/SharedTournamentDto";
import EventMapper from "./EventMapper";
import { GetCurrentGameId } from "../../../../common/constants/Games";

const propKeys = {
    createData: StoreKeys.EVENTCREATOR.CREATE_DATA,
    eventData: StoreKeys.EVENTCREATOR.EVENT_DATA,
    eventDetails: StoreKeys.EVENTCREATOR.EVENT_DETAILS,
    eventFormats: StoreKeys.EVENTCREATOR.EVENT_FORMATS,
    selectedEvent: StoreKeys.EVENTCREATOR.SELECTED_EVENT,
    upcomingEvents: StoreKeys.EVENTCREATOR.UPCOMING_EVENTS,
    games: StoreKeys.EVENTCREATOR.GAMES,
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
        marginBottom: theme.spacing(2),
    },
    fullWidth: {
        width: "100%",
    },
    selectInGrid: {
        minWidth: 200,
    },
    padding: {
        padding: theme.spacing(2),
    },
    paper: {
        color: Colors.primary,
        backgroundColor: Colors.panel,
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
}));

/**
 * @typedef {Object} CreateData
 * @property {string} nameOverride
 * @property {number} rewardTypeId
 * @property {string} imageUrl
 * @property {number} eventId
 * @property {number} gameId
 * @property {number} eventFormatId
 */

/**
 * @typedef {Object} EventCreatorProps
 * @property {CreateData} createData
 * @property {any} eventData
 * @property {any} eventDetails
 * @property {Array<Lookup>} eventFormats
 * @property {SharedTournamentDto} selectedEvent
 * @property {Array<SharedTournamentDto>} upcomingEvents
 * @property {Array<Lookup>} games
 */

function EventCreatorC(props) {
    /** @type {EventCreatorProps} */
    const {
        createData,
        eventData,
        eventDetails,
        eventFormats,
        selectedEvent,
        upcomingEvents,
        games,
    } = props;
    const now = new Date();
    const later = new Date();
    later.setDate(now.getDate() + 30);
    const classes = useStyles();
    const [fromDate, setFromDate] = React.useState(now);
    const [toDate, setToDate] = React.useState(later);

    React.useEffect(() => {
        Store.cacheChanges();
        Store.set(StoreKeys.EVENTCREATOR.CREATE_DATA, {});
        Store.set(StoreKeys.EVENTCREATOR.EVENT_DATA, null);
        Store.set(StoreKeys.EVENTCREATOR.SELECTED_EVENT, null);

        SetLoading(true, "Loading Lookups");

        Promise.all([
            LookupService.getGames().then((games) => {
                Store.set(StoreKeys.EVENTCREATOR.GAMES, games);
            }),
            EventFormatService.getAllFormats().then((formats) => {
                Store.set(StoreKeys.EVENTCREATOR.EVENT_FORMATS, formats);
            }),
        ]).then(() => {
            Store.applyChanges();
            SetLoading(false);
        });
    }, []);

    const onGameSelect = (event) => {
        const value = event.target.value;
        const gameId = Number(value);
        createData.gameId = gameId;
        Store.set(StoreKeys.EVENTCREATOR.CREATE_DATA, createData);
    };

    const onFromChange = (event) => {
        const value = new Date(event.target.value);
        setFromDate(value);
    };

    const onToChange = (event) => {
        const value = new Date(event.target.value);
        setToDate(value);
    };

    const onSearch = () => {
        if (!createData?.gameId) {
            return;
        }

        SetLoading(true, "Loading Upcoming Events");
        EventBuilderService.getUpcomingEvents(
            createData.gameId,
            fromDate,
            toDate
        ).then((events) => {
            events.forEach((e) => {
                e.start = e.start.replace("Z", "");
            });
            Store.set(StoreKeys.EVENTCREATOR.UPCOMING_EVENTS, events);
            Store.set(StoreKeys.EVENTCREATOR.SELECTED_EVENT, null);
            SetLoading(false);
        });
    };

    const onEventFormatSelect = (event) => {
        const value = event.target.value;
        createData.eventFormatId = Number(value);
        Store.set(StoreKeys.EVENTCREATOR.CREATE_DATA, createData);
    };

    const eventNameChange = (event) => {
        const value = event.target.value;
        createData.nameOverride = value;
        Store.set(StoreKeys.EVENTCREATOR.CREATE_DATA, createData);
    };

    const rewardTypeChange = (event) => {
        const value = event.target.value;
        createData.rewardTypeId = value;
        Store.set(StoreKeys.EVENTCREATOR.CREATE_DATA, createData);
    };

    const imageUrlChange = (event) => {
        const value = event.target.value;
        createData.imageUrl = value;
        Store.set(StoreKeys.EVENTCREATOR.CREATE_DATA, createData);
    };

    const loadEventPressed = async () => {
        SetLoading(true, "Loading Event Details");

        try {
            const eventDetails = await EventBuilderService.getEventDetails(
                createData.gameId,
                createData.eventId
            );
            Store.set(StoreKeys.EVENTCREATOR.EVENT_DETAILS, eventDetails);
            SetLoading(false);
        } catch (error) {
            console.log(error);
            SetLoading(false);
            toast("Contact Lor!", { type: "error" });
        }
    };

    /**
     *
     * @param {number} tournamentId
     * @param {Array<SharedTournamentDto>} upcomingEvents
     */
    const selectEvent = (tournamentId, upcomingEvents) => {
        const selectedEvent = upcomingEvents.find(
            (s) => s.id === Number(tournamentId)
        );
        createData.eventId = selectedEvent.id;
        createData.rewardTypeId = getRewardType(selectedEvent.title);
        createData.nameOverride = selectedEvent.title;
        createData.imageUrl = selectedEvent.bannerUrl;

        const changes = {};
        changes[StoreKeys.EVENTCREATOR.SELECTED_EVENT] = selectedEvent;
        changes[StoreKeys.EVENTCREATOR.CREATE_DATA] = createData;
        changes[StoreKeys.EVENTCREATOR.EVENT_DETAILS] = null;
        Store.setMany(changes);
    };

    const renderSearch = () => {
        return (
            <>
                <div>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="game-label">Game</InputLabel>
                        <Select
                            labelId="game-label"
                            value={createData?.gameId ?? ""}
                            onChange={onGameSelect}
                            color="primary"
                            MenuProps={{
                                PaperProps: { className: classes.paper },
                            }}
                        >
                            {games?.map((game) => (
                                <MenuItem value={game.id} key={game.id}>
                                    {game.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        className={classes.formControl}
                        id="date"
                        label="From"
                        type="date"
                        defaultValue={toMMDDYYYY(fromDate)}
                        onChange={onFromChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        className={classes.formControl}
                        id="date"
                        label="To"
                        type="date"
                        defaultValue={toMMDDYYYY(toDate)}
                        onChange={onToChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </>
        );
    };

    const onEventChange = (event) => {
        const seriesId = event.target.value;
        selectEvent(seriesId, upcomingEvents);
    };

    const renderSeriesSelection = () => {
        return (
            <>
                <Paper
                    className={
                        classes.paper +
                        " " +
                        classes.marginBottom +
                        " " +
                        classes.padding
                    }
                    elevation={3}
                >
                    <Typography>Upcoming Tournaments</Typography>
                    <div style={{ overflowY: "scroll", maxHeight: 400 }}>
                        <RadioGroup
                            aria-label="series"
                            name="series"
                            value={selectedEvent?.id ?? ""}
                            onChange={onEventChange}
                        >
                            {upcomingEvents.map((e, i) => {
                                return (
                                    <FormControlLabel
                                        key={i}
                                        value={e.id}
                                        control={<Radio />}
                                        label={
                                            <>
                                                {e.title}(
                                                <a
                                                    href={e.wikiLink}
                                                    target="_blank"
                                                >
                                                    link
                                                </a>
                                                )
                                            </>
                                        }
                                    />
                                );
                            })}
                        </RadioGroup>
                    </div>
                </Paper>
            </>
        );
    };

    const getRewardType = (name) => {
        name = name.toLowerCase();
        if (name.includes("regional")) return RewardTypes.Medal;
        else if (name.includes("major")) return RewardTypes.Trophy;
        else if (name.includes("championship")) return RewardTypes.Globe;
    };

    const renderLoadButton = () => {
        return (
            <div style={{ marginTop: 24 }}>
                <button onClick={loadEventPressed}>Load Event</button>
            </div>
        );
    };

    const onCreate = async (mapperState) => {
        const stageMapping = { ...mapperState };
        for (const stageIndex in stageMapping.stages) {
            const stage = stageMapping.stages[stageIndex];
            stage.matchMappingKeys = stage.matchMappingKeys.map(
                (key) => key.id
            );
            stage.standingsMappingKeys = stage.standingsMappingKeys.map(
                (key) => key.id
            );
        }

        SetLoading(true, "Creating Event");
        /** @type {EventCreateRequestDto} */
        const createRequest = {
            tournamentId: selectedEvent.id,
            liquipediaUrl: createData.liquipediaUrl,
            imageUrl: createData.imageUrl,
            rewardType: createData.rewardTypeId,
            gameId: createData.gameId,
            nameOverride: createData.nameOverride,
            eventFormatId: createData.eventFormatId,
            stageMapping: stageMapping,
        };

        try {
            const data = await EventBuilderService.createEvent(createRequest);
            //Store.set(StoreKeys.EVENTCREATOR.EVENT_DATA, data);
            SetLoading(false);
            toast("Event Created!", { type: "success" });
        } catch (error) {
            console.log(error);
            SetLoading(false);
            toast("Contact Lor!", { type: "error" });
        }
    };

    const renderTeams = () => {
        const teams = eventData.teams;

        return teams.map((team, i) => {
            return (
                <div key={i}>
                    <img
                        src={team.logoUrl}
                        alt="Team Logo"
                        style={{ width: 120, height: 50 }}
                    />
                    <h5>{`${team.name} [${team.abbreviation}]`}</h5>
                    <table>
                        <tbody>
                            {team.members.map((teamMember, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <img
                                                src={teamMember.flag.imageUrl}
                                                style={{
                                                    width: 24,
                                                    height: 24,
                                                }}
                                                title={
                                                    teamMember.flag.nationality
                                                }
                                                alt={
                                                    teamMember.flag.nationality
                                                }
                                            />
                                        </td>
                                        <td>{teamMember.username}</td>
                                        <td>{teamMember.realName}</td>
                                        <td>{teamMember.position}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <hr />
                </div>
            );
        });
    };

    const renderSelectedEvent = () => {
        return (
            <>
                <hr />
                <h1>{selectedEvent.title}</h1>
                <div>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell colSpan={2}>
                                    <input
                                        style={{ width: "100%" }}
                                        value={createData?.nameOverride ?? ""}
                                        onChange={eventNameChange}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Image</TableCell>
                                <TableCell colSpan={2}>
                                    <input
                                        style={{ width: "100%" }}
                                        value={createData?.imageUrl ?? ""}
                                        onChange={imageUrlChange}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Image Preview</TableCell>
                                <TableCell colSpan={2}>
                                    <img
                                        src={createData?.imageUrl}
                                        alt=""
                                        style={{ maxWidth: 300 }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Event Format</TableCell>
                                <TableCell colSpan={2}>
                                    <FormControl
                                        className={classes.selectInGrid}
                                    >
                                        <InputLabel id={"event-format"}>
                                            Event Format
                                        </InputLabel>
                                        <Select
                                            labelId={"event-format"}
                                            value={
                                                createData?.eventFormatId ?? ""
                                            }
                                            onChange={onEventFormatSelect}
                                            color="primary"
                                            MenuProps={{
                                                PaperProps: {
                                                    className: classes.paper,
                                                },
                                            }}
                                            disabled={!!eventDetails}
                                        >
                                            {eventFormats.map((eventFormat) => (
                                                <MenuItem
                                                    key={eventFormat.id}
                                                    value={eventFormat.id}
                                                >
                                                    {eventFormat.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Reward Type</TableCell>
                                <TableCell colSpan={2}>
                                    <FormControl
                                        className={classes.selectInGrid}
                                    >
                                        <InputLabel id={"reward-type"}>
                                            Reward Type
                                        </InputLabel>
                                        <Select
                                            labelId={"reward-type"}
                                            value={
                                                createData?.rewardTypeId ?? ""
                                            }
                                            onChange={rewardTypeChange}
                                            color="primary"
                                            MenuProps={{
                                                PaperProps: {
                                                    className: classes.paper,
                                                },
                                            }}
                                        >
                                            {Object.keys(
                                                RewardTypes.getRewardTypes(
                                                    createData.gameId
                                                )
                                            ).map((key) => (
                                                <MenuItem
                                                    value={RewardTypes[key]}
                                                    key={RewardTypes[key]}
                                                >
                                                    {key}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Start Time</TableCell>
                                <TableCell colSpan={2}>
                                    {toMonthNameDayAndYear(selectedEvent.start)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>End Time</TableCell>
                                <TableCell colSpan={2}>
                                    {toMonthNameDayAndYear(selectedEvent.end)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </>
        );
    };

    return (
        <Container className="event-creator" maxWidth={false}>
            <Helmet>
                <title>Pickstop - Event Creator</title>
            </Helmet>
            <h1>Event Creator</h1>
            {renderSearch()}
            <Button onClick={onSearch}>Search</Button>
            {upcomingEvents?.length > 0 && renderSeriesSelection()}
            {selectedEvent && renderSelectedEvent()}
            {selectedEvent &&
                createData?.gameId &&
                createData?.eventFormatId &&
                createData?.rewardTypeId &&
                !eventDetails &&
                renderLoadButton()}
            {eventDetails && (
                <>
                    <hr />
                    <EventMapper
                        details={eventDetails}
                        format={eventFormats.find(
                            (f) => f.id === createData?.eventFormatId
                        )}
                        onCreate={onCreate}
                    />
                </>
            )}

            {eventData && <div style={{ marginTop: 24 }}>{renderTeams()}</div>}
        </Container>
    );
}

function EventCreator(props) {
    return connect(<EventCreatorC />, propKeys, props);
}

export default EventCreator;
