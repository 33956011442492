import {
    Button,
    Card,
    CardContent,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { LockOutlined, LockOpen, Block } from "@material-ui/icons";
import * as React from "react";
import EventHelper from "common/helpers/eventHelper/eventHelper";
import PicksHelper from "common/picksHelper";
import { ReactComponent as PickstopLogo } from "icons/PickstopLogoGGNew.svg";
import { ReactComponent as PoweredByLP } from "icons/PoweredByLP.svg";
import { Colors } from "styles/Colors";
import { changeUrl } from "common/history";
import { ReactComponent as DiscordIcon } from "icons/DiscordIcon.svg";
import { ReactComponent as RedditIcon } from "icons/RedditIcon.svg";

const useStyles = makeStyles((theme) => ({
    card: {
        color: Colors.primary,
        backgroundColor: `${Colors.panel}`,
        cursor: "pointer",
        margin: "10px",
        height: 330,

        "&:hover": {
            backgroundColor: Colors.panelLight,
        },
    },
    cardContent: {
        padding: "0px",
        height: "100%",
        position: "relative",
        boxSizing: "border-box",
        margin: 8,
    },
    description: {
        justifyContent: "center",
        padding: "0px 20px 10px 20px",
    },
    gameLogo: {
        maxHeight: "100px",
        margin: "30px 0",
    },
    gameTitle: {
        whiteSpace: "nowrap",
    },
    landing: {
        textAlign: "center",
    },
    lockIcon: {
        verticalAlign: "top",
        marginLeft: "2px",
    },
    logo: {
        marginTop: "30px",
        width: "20rem",
    },
    trophy: {
        maxHeight: "40px",
        verticalAlign: "middle",
    },
    trophyContainer: {
        alignSelf: "center",
    },
    welcome: {
        width: "80%",
    },
    eventProgress: {
        position: "absolute",
        bottom: 24,
        display: "block",
        width: "100%",
    },
    icon: {
        cursor: "pointer",
        margin: "2px 4px",
    },
    panel: {
        backgroundColor: Colors.panel,
        width: "fit-content",
        padding: 5,
        marginTop: 8,
        borderRadius: 12,
        position: "relative",
        height: 36,
        display: "flex",
        alignContent: "center",
    },
    lpLogo: {
        marginTop: 24,
        cursor: "pointer",
    },
}));

export default function LandingScreen() {
    const styles = useStyles();
    const [csInfo, setCsInfo] = React.useState({
        eventName: "",
        picksMessage: "",
        picksIcon: <></>,
        color: Colors.primaryDarker,
    });
    const [rlInfo, setRlInfo] = React.useState({
        eventName: "",
        picksMessage: "",
        picksIcon: <></>,
        color: Colors.primaryDarker,
    });

    React.useEffect(() => {
        const loadCurrentEvents = async () => {
            // returns message and icon
            const getGameInfo = (picksHelper) => {
                if (picksHelper.isFinished()) {
                    return {
                        message: "Event complete",
                        color: Colors.primaryDarker,
                        icon: <></>,
                    };
                } else if (picksHelper.isLocked()) {
                    return {
                        message: "Picks currently locked",
                        color: Colors.primaryDarker,
                        icon: (
                            <LockOutlined
                                color={Colors.primaryDarker}
                                className={styles.lockIcon}
                            />
                        ),
                    };
                } else {
                    return {
                        message: "Picks currently open",
                        color: Colors.pickstopGreen,
                        icon: (
                            <LockOpen
                                color={Colors.pickstopGreen}
                                className={styles.lockIcon}
                            />
                        ),
                    };
                }
            };

            const events = await EventHelper.getCurrentEvents();
            const rlInfo = getGameInfo(new PicksHelper(events[0]));
            const csInfo = getGameInfo(new PicksHelper(events[1]));

            setRlInfo({
                eventName: events[0].nameOverride,
                picksMessage: rlInfo.message,
                picksIcon: rlInfo.icon,
                color: rlInfo.color,
            });

            setCsInfo({
                eventName: events[1].nameOverride,
                picksMessage: csInfo.message,
                picksIcon: csInfo.icon,
                color: csInfo.color,
            });
        };
        loadCurrentEvents();
    }, []);

    return (
        <Grid container direction="column" className={styles.landing}>
            <Grid container direction="column" alignItems="center">
                <PickstopLogo className={styles.logo} />
                <h3 className={styles.welcome}>
                    Welcome to Pickstop, a web platform for free pick'em
                    competitions.
                </h3>
                <Grid container className={styles.description}>
                    <Grid
                        item
                        xs={3}
                        sm="auto"
                        className={styles.trophyContainer}
                    >
                        <img
                            className={styles.trophy}
                            src="/images/rewards/TrophiesLeft.png"
                            alt="trophies left"
                        />
                    </Grid>
                    <Grid item xs={6} sm="auto">
                        <p>
                            Pick your winning teams, compete against your
                            friends, and earn trophies for your global ranking.
                        </p>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sm="auto"
                        className={styles.trophyContainer}
                    >
                        <img
                            className={styles.trophy}
                            src="/images/rewards/TrophiesRight.png"
                            alt="trophies right"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={6} md={6} lg={4}>
                    <Card
                        className={styles.card}
                        onClick={() => (window.location.href = "/cs")}
                    >
                        <CardContent className={styles.cardContent}>
                            <Typography
                                component="div"
                                variant="h4"
                                className={styles.gameTitle}
                            >
                                Counter-Strike
                            </Typography>
                            <img
                                className={styles.gameLogo}
                                src="/images/logos/cs2.png"
                                title="CS"
                                alt="CS Logo"
                            />
                            <Typography component="div" variant="h6">
                                {csInfo.eventName}
                            </Typography>
                            <Typography
                                component="div"
                                variant="subtitle1"
                                className={styles.eventProgress}
                                style={{ color: csInfo.color }}
                            >
                                {csInfo.picksMessage}
                                {csInfo.picksIcon}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={6} lg={4} className={styles.gameTile}>
                    <Card
                        className={styles.card}
                        onClick={() => (window.location.href = "/rl")}
                    >
                        <CardContent className={styles.cardContent}>
                            <Typography
                                component="div"
                                variant="h4"
                                className={styles.gameTitle}
                            >
                                Rocket League
                            </Typography>
                            <img
                                className={styles.gameLogo}
                                src="/images/logos/rocketleague2.png"
                                title="RL"
                                alt="RL Logo"
                            />
                            <Typography component="div" variant="h6">
                                {rlInfo.eventName}
                            </Typography>
                            <Typography
                                component="div"
                                variant="subtitle1"
                                className={styles.eventProgress}
                                style={{ color: rlInfo.color }}
                            >
                                {rlInfo.picksMessage}
                                {rlInfo.picksIcon}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} container justifyContent="center">
                    <div className={styles.panel}>
                        <Button
                            onClick={() => {
                                changeUrl("/about-us");
                            }}
                            color="secondary"
                            style={{ backgroundColor: Colors.body }}
                        >
                            <img
                                src="/images/LogoOnly.png"
                                width="36"
                                height="36"
                            />
                            About Us
                        </Button>
                        <DiscordIcon
                            title="Discord"
                            onClick={() => {
                                window.open(
                                    "https://discord.gg/r8ujzkRYeF",
                                    "_blank"
                                );
                            }}
                            className={styles.icon}
                        />
                        <RedditIcon
                            title="Reddit"
                            onClick={() => {
                                window.open(
                                    "https://www.reddit.com/user/pickstopgg/",
                                    "_blank"
                                );
                            }}
                            className={styles.icon}
                        />
                    </div>
                </Grid>
                <Grid xs={12} container justifyContent="center">
                    <div
                        className={styles.lpLogo}
                        onClick={() => {
                            window.open("https://liquipedia.net", "_blank");
                        }}
                    >
                        <PoweredByLP width={320} height={46} />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}
