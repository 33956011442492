import React from "react";
import { FreeformBracketConnectorsProps } from "bracket-system/types";
import FreeformConnectorService from "./freeform-connector-service";
import { findFreeformBracketMatchById } from "common/helpers/bracketHelper";

const FreeformBracketConnectors = ({
    matches,
}: FreeformBracketConnectorsProps) => {
    return (
        <>
            {matches?.map((part) => {
                return part.columns?.map((c, colIndex) => {
                    const nextColIndex = colIndex + 1;
                    let nextColumn = null;
                    if (part.columns?.length > nextColIndex + 1) {
                        nextColumn = part.columns[nextColIndex];
                    }

                    return c.matches?.map((m, mIndex) => {
                        if (!m.nextMatchId) {
                            return <></>;
                        }

                        const directFeed =
                            nextColumn?.matches?.length == c.matches?.length;

                        // DO NOT CONNECT CROSS PARTS
                        const nextMatch = findFreeformBracketMatchById(
                            matches,
                            m.nextMatchId
                        );
                        if (nextMatch.part != m.part) {
                            return <></>;
                        }

                        const from =
                            FreeformConnectorService.getMatchConnectorLocation(
                                m.id,
                                "out"
                            );
                        let to =
                            FreeformConnectorService.getMatchConnectorLocation(
                                m.nextMatchId,
                                "in"
                            );

                        if (directFeed) {
                            to = { x: to.x, y: from.y };
                        }

                        const halfX = Math.floor((from.x + to.x) / 2);
                        const path = `M ${from.x} ${from.y} L ${halfX} ${from.y} L ${halfX} ${to.y} L ${to.x} ${to.y}`;

                        return (
                            <path
                                d={path}
                                stroke="#373737"
                                fill="transparent"
                            />
                        );
                    });
                });
            })}
        </>
    );
};

export default FreeformBracketConnectors;
