import React from "react";
import { MatchComponentProps } from "../../types";
import {
    Score,
    Side,
    StyledMatch,
    LogoImage,
    Team,
    TopText,
    BottomText,
    Wrapper,
    Line,
    Anchor,
} from "./styles";

function Match({
    bottomHovered,
    bottomParty,
    bottomText,
    bottomWon,
    match,
    onMatchClick,
    onMouseEnter,
    onMouseLeave,
    onPartyClick,
    topHovered,
    topParty,
    topText,
    topWon,
    matchHighlighted,
    hideSeriesScore,
    isQualifierColumn,
    width,
    height,
    isEditing,
}: MatchComponentProps) {
    const hasWinner = topWon || bottomWon;
    const winnerParty = !hasWinner
        ? {
              id: undefined,
              correctParticipant: false,
              correctPlacement: false,
              logoUrl: null,
              name: "TBD",
              resultText: "",
          }
        : topWon
        ? topParty
        : bottomParty;
    const loserParty = !hasWinner
        ? {
              id: undefined,
              correctParticipant: false,
              correctPlacement: false,
              logoUrl: null,
              name: "TBD",
              resultText: "",
          }
        : topWon
        ? bottomParty
        : topParty;

    const winnerHovered =
        hasWinner &&
        ((topHovered && winnerParty.id === topParty.id) ||
            (bottomHovered && winnerParty.id === bottomParty.id));

    const loserHovered =
        hasWinner &&
        ((topParty && loserParty.id === topParty.id) ||
            (bottomHovered && loserParty.id === bottomParty.id));

    return (
        <Wrapper>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
				<TopText>{topText}</TopText>
				{typeof onMatchClick === "function" && (
					<Anchor
						onClick={(e) => {
							e.stopPropagation();
							onMatchClick({ match, topWon, bottomWon });
						}}
					>
						<TopText>Match Details</TopText>
					</Anchor>
				)}
			</div> */}
            <StyledMatch>
                {isQualifierColumn && (
                    <>
                        <Side
                            onMouseEnter={() =>
                                onMouseEnter(winnerParty?.id, match.id)
                            }
                            onMouseLeave={onMouseLeave}
                            won={winnerParty?.id !== undefined}
                            hovered={winnerHovered}
                            correctParticipant={winnerParty?.correctParticipant}
                            correctPlacement={winnerParty.correctPlacement}
                            highlighted={matchHighlighted}
                        >
                            {winnerParty?.logoUrl && (
                                <LogoImage src={winnerParty?.logoUrl} alt="" />
                            )}
                            <Team>{winnerParty?.name}</Team>
                            {!hideSeriesScore && (
                                <Score won={topWon}>
                                    {winnerParty?.resultText}
                                </Score>
                            )}
                        </Side>
                        {match.bothTeamsAdvance && (
                            <Side
                                onMouseEnter={() =>
                                    onMouseEnter(loserParty?.id, match.id)
                                }
                                onMouseLeave={onMouseLeave}
                                won={loserParty?.id !== undefined}
                                hovered={loserHovered}
                                correctParticipant={
                                    loserParty?.correctParticipant
                                }
                                correctPlacement={loserParty.correctPlacement}
                                highlighted={matchHighlighted}
                                loserQualifies={true}
                            >
                                {loserParty?.logoUrl && (
                                    <LogoImage
                                        src={loserParty?.logoUrl}
                                        alt=""
                                    />
                                )}
                                <Team>{loserParty?.name}</Team>
                                {!hideSeriesScore && (
                                    <Score won={topWon}>
                                        {loserParty?.resultText}
                                    </Score>
                                )}
                            </Side>
                        )}
                    </>
                )}
                {!isQualifierColumn && (
                    <>
                        <Side
                            onMouseEnter={() =>
                                onMouseEnter(topParty?.id, match.id)
                            }
                            onMouseLeave={onMouseLeave}
                            won={topWon}
                            hovered={topHovered}
                            correctParticipant={topParty?.correctParticipant}
                            correctPlacement={topParty.correctPlacement}
                            highlighted={matchHighlighted}
                            onClick={(e) => {
                                if (onPartyClick) {
                                    e.stopPropagation();
                                    onPartyClick(
                                        e,
                                        match,
                                        true,
                                        topParty,
                                        topWon
                                    );
                                }
                            }}
                        >
                            {topParty?.logoUrl && (
                                <LogoImage src={topParty?.logoUrl} alt="" />
                            )}
                            <Team>{topParty?.name}</Team>
                            {!hideSeriesScore && (
                                <Score won={topWon}>
                                    {topParty?.resultText}
                                </Score>
                            )}
                        </Side>
                        <Line highlighted={topHovered || bottomHovered} />
                        <Side
                            onMouseEnter={() =>
                                onMouseEnter(bottomParty?.id, match.id)
                            }
                            onMouseLeave={onMouseLeave}
                            won={bottomWon}
                            hovered={bottomHovered}
                            correctParticipant={bottomParty?.correctParticipant}
                            correctPlacement={bottomParty?.correctPlacement}
                            highlighted={matchHighlighted}
                            onClick={(e) => {
                                if (onPartyClick) {
                                    e.stopPropagation();
                                    onPartyClick(
                                        e,
                                        match,
                                        false,
                                        bottomParty,
                                        bottomWon
                                    );
                                }
                            }}
                        >
                            {bottomParty?.logoUrl && (
                                <LogoImage src={bottomParty?.logoUrl} alt="" />
                            )}
                            <Team>{bottomParty?.name}</Team>
                            {!hideSeriesScore && (
                                <Score won={bottomWon}>
                                    {bottomParty?.resultText}
                                </Score>
                            )}
                        </Side>
                    </>
                )}
            </StyledMatch>
            {/* <BottomText>{bottomText ?? " "}</BottomText> */}
        </Wrapper>
    );
}

export default Match;
