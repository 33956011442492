import React, { useContext } from "react";
import { ThemeProvider } from "styled-components";
import {
    matchContext,
    MatchContextProvider,
} from "bracket-system/core/match-context";

import { FreeformBracketProps } from "../types";
import { defaultStyle, getCalculatedStyles } from "../settings";

import defaultTheme from "../themes/themes";
import { useEffect } from "react";
import FreeformBracketPart from "./freeform-bracket-part";
import { FreeformBracketPart as FreeformBracketPartModel } from "../types";
import FreeformConnectorService from "./freeform-connector-service";
import FreeformBracketConnectors from "./freeform-bracket-connectors";

const FreeformBracket = ({
    matches,
    officialMatches,
    highlightMatchIds,
    matchComponent,
    onMatchClick,
    onPartyClick,
    svgWrapper: SvgWrapper = ({ children }) => <>{children}</>,
    theme = defaultTheme,
    options: { style: inputStyle } = {
        style: defaultStyle,
    },
    onPartClick,
    onColumnClick,
    hideSeriesScore,
    canEdit = false,
    onUpdate,
}: FreeformBracketProps) => {
    const style = {
        ...defaultStyle,
        ...inputStyle,
        roundHeader: {
            ...defaultStyle.roundHeader,
            ...inputStyle.roundHeader,
        },
        lineInfo: {
            ...defaultStyle.lineInfo,
            ...inputStyle.lineInfo,
        },
    };

    FreeformConnectorService.setFreeformBracket(matches);
    FreeformConnectorService.setOnUpdateCallback(onUpdate);
    const calculatedStyles = getCalculatedStyles(style, hideSeriesScore);

    const partSizes = calculatePartSizes(matches, calculatedStyles);
    const bracketWidth =
        partSizes.map((s) => s.width).sort((a, b) => b - a)[0] ?? 0;
    const bracketHeight = sumArray(partSizes.map((s) => s.height));

    return (
        <ThemeProvider theme={theme}>
            <SvgWrapper
                bracketWidth={bracketWidth}
                bracketHeight={bracketHeight}
                startAt={[0, 0]}
                background={theme.canvasBackground}
            >
                <svg
                    height={bracketHeight ?? 0}
                    width={bracketWidth ?? 0}
                    viewBox={`0 0 ${bracketWidth} ${bracketHeight}`}
                >
                    <svg
                        id="freeform-bracket"
                        height={bracketHeight ?? 0}
                        width={bracketWidth ?? 0}
                        viewBox={`0 0 ${bracketWidth} ${bracketHeight}`}
                        onMouseDown={(e) => {
                            if (canEdit) {
                                FreeformConnectorService.startDrag(e);
                            }
                        }}
                        onMouseMove={(e) => {
                            if (canEdit) {
                                FreeformConnectorService.drag(e);
                            }
                        }}
                        onMouseUp={(e) => {
                            if (canEdit) {
                                FreeformConnectorService.endDrag(e);
                            }
                        }}
                    >
                        <MatchContextProvider>
                            <MatchColoringProvider
                                newHighlightedMatchIds={highlightMatchIds}
                                newOfficialMatches={officialMatches}
                            />
                            {(matches ?? officialMatches)?.map(
                                (part, index) => {
                                    const offset = sumArray(
                                        partSizes
                                            .slice(0, index)
                                            .map((s) => s.height)
                                    );
                                    return (
                                        <FreeformBracketPart
                                            {...{
                                                identifier: part.identifier,
                                                columns: part.columns,
                                                offsetTop: offset,
                                                calculatedStyles,
                                                matchComponent,
                                                hideSeriesScore,
                                                onPartClick,
                                                onColumnClick,
                                                onMatchClick,
                                                onPartyClick,
                                                canEdit,
                                            }}
                                        />
                                    );
                                }
                            )}
                            <FreeformBracketConnectors matches={matches} />
                        </MatchContextProvider>
                    </svg>
                </svg>
            </SvgWrapper>
        </ThemeProvider>
    );
};

function MatchColoringProvider({ newHighlightedMatchIds, newOfficialMatches }) {
    const {
        state: { highlightedMatchIds, officialMatches },
        dispatch,
    } = useContext(matchContext);

    useEffect(() => {
        if (highlightedMatchIds?.length !== newHighlightedMatchIds?.length) {
            dispatch({
                type: "SET_MATCH_HIGHLIGHTS",
                payload: {
                    highlightedMatchIds: newHighlightedMatchIds,
                },
            });
        }

        if (officialMatches?.length !== newOfficialMatches?.length) {
            dispatch({
                type: "SET_OFFICIAL_PICKS",
                payload: {
                    officialMatches: newOfficialMatches,
                },
            });
        }
    });

    return <></>;
}

class Size {
    width: number;
    height: number;
}

function calculatePartSizes(
    parts: FreeformBracketPartModel[],
    calculatedStyles: any
): Size[] {
    const { columnWidth, rowHeight, roundHeader } = calculatedStyles;

    const sizes = [];
    if (!parts) {
        return sizes;
    }

    for (const part of parts) {
        let width = 0;
        let maxHeight = 0;
        for (const column of part.columns) {
            let columnHeight = 20 + column.paddingTop;
            if (roundHeader.isShown) {
                columnHeight += roundHeader.height + roundHeader.marginBottom;
            }

            for (const match of column.matches) {
                columnHeight += match.paddingTop + rowHeight;
            }

            if (columnHeight > maxHeight) {
                maxHeight = columnHeight;
            }

            width += columnWidth;
            width += column.paddingLeft;
        }

        sizes.push({
            width,
            height: maxHeight,
        });
    }

    return sizes;
}

function sumArray(array: number[]) {
    let total = 0;
    for (const num of array) {
        total += num;
    }

    return total;
}

export default FreeformBracket;
