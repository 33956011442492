import React from "react";

export default function FreeformBracketRoundHeader({
    x,
    y = 0,
    width,
    roundHeaderStyle,
    canvasPadding,
    onClick = null,
    headerText,
}) {
    return (
        // @ts-ignore
        <g style={{ pointerEvents: "bounding-box" }} onClick={onClick}>
            <rect
                x={x}
                y={y}
                width={width}
                height={roundHeaderStyle.height}
                fill={roundHeaderStyle.backgroundColor}
                rx="3"
                ry="3"
            />

            <text
                fontFamily={roundHeaderStyle.fontFamily}
                x={x + width / 2}
                y={y + roundHeaderStyle.height / 2}
                style={{
                    fontSize: `${roundHeaderStyle.fontSize}
            px`,
                    color: roundHeaderStyle.fontColor,
                }}
                fill="currentColor"
                dominantBaseline="middle"
                textAnchor="middle"
            >
                {headerText}
            </text>
        </g>
    );
}
